import Editor from '@tinymce/tinymce-vue';
// Import TinyMCE
import 'tinymce/tinymce';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';
// import 'tinymce/skins/ui/oxide/skin.min.css';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';

export default {
  name: 'tiny-editor',
  components: {
    editor: Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    mceId: {
      type: String,
      default: 'uuid'
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      id: this.mceId,
      content: this.value,
      editor: false,
      disabled: !this.editable,
      showhtml: false,
      menubar: this.editable
    };
  },
  methods: {
    loaded(event, editor) {
      // tiny has loaded, now say we are loaded
      // update the editor
      this.editor = editor;
    },
    update() {
      // pass updated content back to the parent
      this.$emit('input', this.content);
    },
    viewsource() {
      this.showhtml = !this.showhtml;
      this.content = this.editor.getContent();
    }
  },
  watch: {
    cancelEdit: function() {
      this.content = this.value;
    }
  }
};
